export const EAST_BAY_RUN_CLUB_DATA = [

  {
    id: "womens-eastbay",
    title: "Women's Run Club East Bay",
    subtitle: "East Bay, Bay Area",
    description: "A new running community for women.",
    videoSrc: require('../assets/womens.mp4'),
    img: require('../assets/wrc.png'),
    categories: ["Female-only", "Free", "Evening"],
    day: ["Tuesday", "Thursday"],
    daysOfWeek: ["Tuesday", "Thursday"],
    city: "East Bay", // Added city
  },

  {
    id: "coffee-run-collective",
    title: "Coffee Run Collective",
    subtitle: "East Bay, Bay Area",
    description: "Runs and fresh brews.",
    videoSrc: require('../assets/coffeecollective.mp4'),
    img: require('../assets/coffecollective1.jpeg'),
    // categories: ["Free", "Evening", ],
    // day: ["Tuesday", "Thursday"],
    // daysOfWeek: ["Tuesday", "Thursday"],
    city: "East Bay", // Added city
  }


  ];

