import React, { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import "./card.css";
import "./VerifiedBadge.css";
import DayOfWeekChip from './dayChip';

// Video preload cache to prevent reloading
const videoCache = new Map();

const globalMuteState = {
  isMuted: false,
  listeners: new Set(),
  toggle() {
    this.isMuted = !this.isMuted;
    this.notifyListeners();
  },
  setMuted(muted) {
    this.isMuted = muted;
    this.notifyListeners();
  },
  addListener(listener) {
    this.listeners.add(listener);
  },
  removeListener(listener) {
    this.listeners.delete(listener);
  },
  notifyListeners() {
    this.listeners.forEach(listener => listener(this.isMuted));
  }
};

// Preload manager to handle video buffering
const preloadVideo = (src) => {
  if (videoCache.has(src)) {
    return videoCache.get(src);
  }

  const video = document.createElement('video');
  video.preload = 'auto';
  video.src = src;
  
  const promise = new Promise((resolve) => {
    video.addEventListener('canplay', () => resolve(video), { once: true });
    video.load();
  });

  videoCache.set(src, promise);
  return promise;
};

export default function CardComponent(props) {
  const videoRef = useRef(null);
  const preloadRef = useRef(null);
  const [isMuted, setIsMuted] = useState(globalMuteState.isMuted);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const observerRef = useRef(null);

  // Start preloading as soon as component mounts if video exists
  useEffect(() => {
    if (props.data.videoSrc) {
      preloadRef.current = preloadVideo(props.data.videoSrc);
    }
  }, [props.data.videoSrc]);

  useEffect(() => {
    setIsTouchDevice(
      "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    );

    const handleMuteChange = (muted) => {
      setIsMuted(muted);
      if (videoRef.current) {
        videoRef.current.muted = muted;
      }
    };

    globalMuteState.addListener(handleMuteChange);

    return () => {
      globalMuteState.removeListener(handleMuteChange);
    };
  }, []);

  const playVideo = useCallback(async () => {
    const video = videoRef.current;
    if (!video) return;

    try {
      video.muted = globalMuteState.isMuted;
      
      // If video hasn't started loading yet, wait for preload
      if (!isVideoLoaded && preloadRef.current) {
        await preloadRef.current;
        setIsVideoLoaded(true);
      }

      // Reset if near end
      if (video.currentTime > 29.5) {
        video.currentTime = 0;
      }

      const playPromise = video.play();
      if (playPromise !== undefined) {
        await playPromise;
        setIsPlaying(true);
      }
    } catch (error) {
      console.error("Play error:", error);
      // Fallback to muted playback
      if (!video.muted) {
        video.muted = true;
        globalMuteState.setMuted(true);
        try {
          await video.play();
          setIsPlaying(true);
        } catch (e) {
          console.error("Muted playback failed:", e);
        }
      }
    }
  }, [isVideoLoaded]);

  const pauseVideo = useCallback(() => {
    const video = videoRef.current;
    if (video && !video.paused) {
      video.pause();
      setIsPlaying(false);
    }
  }, []);

  // Intersection Observer setup
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const options = {
      root: null,
      rootMargin: '100px 0px', // Increased margin for earlier preload
      threshold: isTouchDevice ? 0.9 : 0.3, // Lower threshold for desktop
    };

    const handleIntersection = async (entries) => {
      entries.forEach((entry) => {
        const isNowInView = entry.isIntersecting;
        setIsInView(isNowInView);
        
        if (isNowInView) {
          // Start preloading immediately when in view
          if (!isVideoLoaded && preloadRef.current) {
            preloadRef.current.then(() => {
              setIsVideoLoaded(true);
              if (isTouchDevice || isHovering) {
                playVideo();
              }
            });
          } else if (isTouchDevice) {
            playVideo();
          }
        } else {
          pauseVideo();
        }
      });
    };

    observerRef.current = new IntersectionObserver(handleIntersection, options);
    observerRef.current.observe(video);

    return () => {
      if (observerRef.current) {
        observerRef.current.unobserve(video);
        observerRef.current.disconnect();
      }
    };
  }, [isVideoLoaded, isTouchDevice, isHovering, playVideo, pauseVideo]);

  const handleMouseEnter = useCallback(async () => {
    setIsHovering(true);
    if (!isTouchDevice && isInView) {
      const video = videoRef.current;
      if (video) {
        if (isVideoLoaded) {
          playVideo();
        } else if (preloadRef.current) {
          // Wait for preload to complete then play
          await preloadRef.current;
          setIsVideoLoaded(true);
          playVideo();
        }
      }
    }
  }, [isTouchDevice, isInView, isVideoLoaded, playVideo]);

  const handleMouseLeave = useCallback(() => {
    setIsHovering(false);
    if (!isTouchDevice) {
      pauseVideo();
    }
  }, [isTouchDevice, pauseVideo]);

  const renderMedia = () => {
    if (props.data.videoSrc) {
      return (
        <div
          className="video-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={(e) => {
            if (isTouchDevice) {
              e.preventDefault();
              e.stopPropagation();
              isPlaying ? pauseVideo() : playVideo();
            }
          }}
        >
          <video
            ref={videoRef}
            className="media-video"
            playsInline
            muted={isMuted}
            loop
            poster={props.data.img}
          >
            <source src={props.data.videoSrc} type="video/mp4" />
          </video>
          {isTouchDevice && <div className="video-overlay" />}
          <button
            className="mute-button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              globalMuteState.toggle();
            }}
            aria-label={isMuted ? "Unmute video" : "Mute video"}
          >
            {isMuted ? (
              <FaVolumeMute size={18} color="white" />
            ) : (
              <FaVolumeUp size={18} color="white" />
            )}
          </button>
        </div>
      );
    }

    return props.data.img ? (
      <img
        src={props.data.img}
        className="img"
        alt={props.data.title}
        loading="lazy"
      />
    ) : (
      <div className="placeholder-image">No image available</div>
    );
  };

  return (
    <div className="card-wrapper">
      <Link 
        to={`/club/${props.data.city.toLowerCase().replace(/\s+/g, '')}/${props.data.id}`} 
        className="card-link"
      >
        <div className="img-holder">{renderMedia()}</div>
        <div className="card-content">
          <div className="card-title-wrapper">
            <div className="card-title">{props.data.title}</div>
          </div>
          <div className="card-subtitle">{props.data.subtitle}</div>
          <div className="card-description">{props.data.description}</div>
          <DayOfWeekChip days={props.data.daysOfWeek} />
        </div>
      </Link>
    </div>
  );
}